import { Injectable } from "@angular/core";
import { CardStatus, IThread, IThreadCard, IVaultRequestCardState } from "@findex/threads";
import { ThreadCardService } from "./thread-card.service";
import { ThreadsService } from "./threads.service";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";

@Injectable()
export class CloseThreadService {
    constructor(
        private cardsService: ThreadCardService,
        private threadsService: ThreadsService,
        private calendarService: CalendarService
    ) { }

    async getPendingRFI(threadId: string): Promise<string[]> {
        const states = await this.getActiveRequestCardStates(threadId);
        return states
            .filter(({ requestItems }) =>
                requestItems.some(({ response }) => response?.complete?.state !== true)
            ).map(({ title }) => title);
    }

    async threadHasUpcomingMeetings(threadId: string): Promise<boolean> {
        const allUpcomingMeetings = await this.calendarService.getUpcomingMeetings().toPromise();
        return allUpcomingMeetings.some(meeting => meeting.threadId === threadId);
    }

    async findThreadsToMigrateMeetings(thread: IThread, userId: string): Promise<IThread[]> {
        const requiredParticipantIds = thread.participants.map(participant => participant.id);
        const activeThreads = await this.threadsService.getActiveThreadsByUserId(userId);
        return activeThreads
            .filter(activeThread => activeThread.id !== thread.id)
            .filter(activeThread => {
                const participantIds = activeThread.participants.map(participant => participant.id);

                return requiredParticipantIds.every(requiredParticipantId =>
                    participantIds.some(participantId => requiredParticipantId === participantId)
                );
            });
    }

    async migrateCalendarCards(threadId: string, targetThreadId: string) {
        return this.cardsService.migrateCalendarCards(threadId, targetThreadId).toPromise();
    }

    private async getActiveRequestCardStates(threadId: string): Promise<IVaultRequestCardState[]> {
        const cards = await this.getActiveCards(threadId);
        const states = await Promise.all(cards.map(card => this.cardsService.getCardState<IVaultRequestCardState>(threadId, card.id).toPromise()));

        return states
        .filter(card => card.state) //card has state
        .filter(({ state }) => state.requestItems) // state request items, meaning it is of type IVaultRequestCardState
        .map(({ state }) => state) // return only state objects
    }

    private async getActiveCards(threadId: string): Promise<IThreadCard[]> {
        const cards = await this.cardsService.getCards(threadId).toPromise();
        return cards.filter(card => card.status !== CardStatus.Deleted && card.status !== CardStatus.Removed);
    }
}
