import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IThread } from "@findex/threads";
import { map, take } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { ConfirmModalComponent } from "../../../shared/components/confirm-modal/confirm-modal.component";
import { CloseThreadService } from "../../services/close-thread-prompt.service";
export interface CloseThreadPromptParams {
    thread: IThread;
    workflowStepName: string;
}

export enum WorkflowStepName {
    cancelled = "cancelled",
    closed = "closed"
}

@Component({
    selector: "app-close-thread-prompt",
    templateUrl: "./close-thread-prompt.component.html",
    styleUrls: ["./close-thread-prompt.component.scss"],
    providers: [CloseThreadService]
})
export class CloseThreadPromptComponent implements OnInit {
    threads: IThread[];
    selectedThread: IThread = null;
    loading = true;
    error: string;
    hasUpcomingMeetings: boolean;
    workflowSteps = WorkflowStepName;

    workflowStepName: string;
    pendingTasks: string[];

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CloseThreadPromptParams,
        private dialogRef: MatDialogRef<ConfirmModalComponent>,
        private authService: AuthService,
        private closeThreadService: CloseThreadService
    ) {
        this.workflowStepName = data.workflowStepName;
    }

    async ngOnInit() {
        const userId = await this.authService
            .getUser()
            .pipe(
                map(val => val.id),
                take(1)
            )
            .toPromise();

        this.pendingTasks = await this.closeThreadService.getPendingRFI(this.data.thread.id);
        if (!this.pendingTasks?.length) {
            this.hasUpcomingMeetings = await this.closeThreadService.threadHasUpcomingMeetings(this.data.thread.id);
            if (this.hasUpcomingMeetings) {
                this.threads = await this.closeThreadService.findThreadsToMigrateMeetings(this.data.thread, userId);
            }
        }

        this.loading = false;
    }

    async copyMeetingsCards(targetThreadId: string) {
        this.loading = true;
        this.error = null;
        try {
            await this.closeThreadService.migrateCalendarCards(this.data.thread.id, targetThreadId);
            this.dialogRef.close(true);
        } catch (err) {
            this.error = "Sorry, something went wrong";
        } finally {
            this.loading = false;
        }
    }

    close(val: boolean) {
        this.dialogRef.close(val);
    }
}
